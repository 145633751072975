import { Assessment, EmojiEvents, PlayArrow } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  Typography,
} from "@mui/material";
import Pagination from "components/Pagination/Pagination";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_PATH } from "services/apipath";
import { postApi } from "services/axiosInstance";

const MockHistory = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 10;
  const navigate = useNavigate();

  const [history, setHistory] = useState([]);
  const [totalHistory, setTotalHistory] = useState([]);
  const getQuestionsList = async () => {
    try {
      const res = await postApi(`${API_PATH.MOCK_TEST.MOCK_TEST_HISTORY}`, {
        page: currentPage,
        limit: ITEMS_PER_PAGE,
      });

      if (res.status === 200) {
        setHistory(res.data.data.attempts);
        setTotalHistory(res.data.data.totalData);
        // setQuestions(res.data.data || []); // Set questions
        // setTotalQuestions(res.data.pagination.totalQuestions || 0); // Set total questions count
      } else {
      }
    } catch (error) { }
  };

  // Fetch questions whenever the page changes or the component mounts
  useEffect(() => {
    getQuestionsList();
  }, [currentPage]);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
      window.scrollTo(0, 0); // Scroll to top when the page changes
    }
  };

  // Calculate total pages based on total questions and ITEMS_PER_PAGE
  const totalPages = Math.ceil(totalHistory / ITEMS_PER_PAGE);

  const handleAnalysis = (id, studentId) => {
    navigate(`/analysis/${id}/${studentId}`);
  };

  const handleResult = (id) => {
    navigate(`/result/${id}`);
  };
  const handleResume = (id, questionId) => {
    navigate(`/mock-test/${id}`, {
      state: { testId: questionId, fromResume: true },
    });
  };
  return (
    <SoftBox>
      <SoftBox>
        <Grid container>
          <Grid item xs={12} md={12}>
            <Card id="attempt-history">
              <SoftBox pt={1} px={2}>
                <SoftTypography variant="h5" fontWeight="medium">
                  Mock Test History
                </SoftTypography>
              </SoftBox>

              <SoftBox pt={2} pb={2} px={2}>
                <SoftBox
                  component="ul"
                  display="flex"
                  flexDirection="column"
                  p={0}
                  m={0}
                  sx={{
                    height: "70vh",
                    overflowY: "scroll",
                  }}
                >
                  {history.map((attempt, index) => (
                    <Grid item xs={12} key={index}>
                      <Card
                        sx={{
                          mx: 1,
                          color: "black",
                          borderRadius: "15px",
                          backgroundColor: "#F8F9FA",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                          py: "6px",
                          mb: "10px",
                        }}
                      >
                        <CardContent
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "14px !important",
                            position: "relative",
                          }}
                        >
                          <Typography
                            variant="body2"
                            color="black"
                            sx={{ fontSize: "14px", pl: "10px" }}
                          >
                            Attempted:{" "}
                            <span
                              style={{
                                fontSize: "14px",
                              }}
                            >
                              { moment(attempt.updatedAt).format(
                                "DD-MM-YYYY, h:mm A"
                              )}
                            </span>
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              width: "50%",
                              pr: "14px",
                            }}
                          >
                            <Box>
                              <Typography
                                variant="p"
                                sx={{
                                  backgroundColor: "#FFB300",
                                  borderRadius: "15px",
                                  padding: "8px 15px",
                                  fontSize: "14px",
                                }}
                              >
                                {attempt?.fullTestId?.TestName}
                              </Typography>
                            </Box>
                            {attempt.testStatus === "completed" && (
                              <Box sx={{ px: "10px" }}>
                                <Button
                                  variant="contained"
                                  color="success"
                                  onClick={() =>
                                    handleAnalysis(
                                      attempt._id,
                                      attempt.studentId._id
                                    )
                                  }
                                  sx={{
                                    marginLeft: 2,
                                    borderRadius: "15px",
                                    padding: "8px 15px",
                                  }}
                                  startIcon={<Assessment />}
                                >
                                  Analysis
                                </Button>
                              </Box>
                            )}
                            {attempt.testStatus === "completed" && (
                              <Box>
                                <Button
                                  variant="contained"
                                  color="success"
                                  onClick={() => handleResult(attempt._id)}
                                  sx={{
                                    marginLeft: 2,
                                    borderRadius: "15px",
                                    padding: "8px 15px",
                                  }}
                                  startIcon={<EmojiEvents />}
                                >
                                  Result
                                </Button>
                              </Box>
                            )}
                            {attempt.testStatus !== "completed" && (
                              <Box>
                                <Button
                                  variant="contained"
                                  color="success"
                                  onClick={() =>
                                    handleResume(
                                      attempt.fullTestId._id,
                                      attempt.nextQuestion
                                    )
                                  }
                                  sx={{
                                    marginLeft: 2,
                                    borderRadius: "15px",
                                    padding: "8px 15px",
                                  }}
                                  startIcon={<PlayArrow />}
                                >
                                  Resume
                                </Button>
                              </Box>
                            )}
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </SoftBox>
              </SoftBox>
              <Box
                sx={{
                  p: 2,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderTop: "1px solid rgba(255, 255, 255, 0.1)",
                  bgcolor: "#1a1a2e",
                }}
              >
                <Pagination
                  totalPages={totalPages}
                  currentPage={currentPage}
                  onPageChange={handlePageChange}
                />
              </Box>
            </Card>
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
};

export default MockHistory;